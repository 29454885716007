import { createGlobalStyle } from '@/style';

export default createGlobalStyle`
  html {
    color: text;
    font-size: 16px;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }
  body {
    color: text;
    background-color: background;
    overflow: auto;
    height: 100vh;
    width: 100vw;
    font-family: base !important;
    input, button, textarea, select, optgroup {
      font-family: base;
    }
  }

  /* Remove focus outline */
  :root {
    --webkit-focus-ring-color: transparent !important;
  }
  button, link {
    :focus {
      outline: none;
    }
  }

  /* Text Selection Effect */
  ::selection {
    background-color: primary; /* WebKit/Blink Browsers */
    color: text;
  }
  ::-moz-selection {
    background-color: primary; /* Gecko Browsers */
    color: text;
  }

  .pointer-events-none {
    pointer-events: none !important;
    * {
      pointer-events: none !important;
    }
  }
`;
