import { FC } from 'react';

import { ResourceHubLayoutProvider } from './resource-hub-layout';
import { ResourceHubStateProvider } from './resource-hub-state';

export const ResourceHubProvider: FC = ({ children }) => (
  <ResourceHubStateProvider>
    <ResourceHubLayoutProvider>{children}</ResourceHubLayoutProvider>
  </ResourceHubStateProvider>
);
