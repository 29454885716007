import { InputHTMLAttributes } from 'react';

import { Layout } from '@/elements';
import { TFormItem, withFormItem } from '@/lib/Form';
import useFormItem from '@/lib/Form/FormItemContext';

import { Input, Check, LabelText } from './components';

import * as shared from '../shared';

const Checkbox: TFormItem<InputHTMLAttributes<any>> = ({ label, ...rest }) => {
  const { registration, value } = useFormItem<boolean>();

  return (
    <shared.Label>
      <Layout.HStack alignItems='center'>
        <shared.InputBorder>
          <Input {...rest} {...registration} type='checkbox' />
          <Check $isChecked={value} />
        </shared.InputBorder>
        <LabelText pb={0} pt='2px'>
          {label}
        </LabelText>
      </Layout.HStack>
      <shared.ErrorMessage />
    </shared.Label>
  );
};

export default withFormItem(Checkbox);
