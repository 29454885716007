/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback } from 'react';

import { Waypoint } from 'react-waypoint';

import useTrack from './context';

const TrackScroll: FC<{
  name: string;
  trackEnter?: boolean;
  enter?: {
    [prop: string]: any;
  };
  trackExit?: boolean;
  exit?: {
    [prop: string]: any;
  };
}> = ({ name, trackEnter, enter, trackExit, exit, children, ...rest }) => {
  const { track } = useTrack();
  const trackOnEnter = useCallback(() => {
    if (trackEnter && track) track('Scroll Enter', name, enter);
  }, [track, trackEnter, enter]);
  const trackOnExit = useCallback(
    ({ currentPosition }) => {
      if (trackExit && track)
        track('Scroll Exit', name, {
          position: currentPosition,
          ...exit,
        });
    },
    [track, trackExit, exit]
  );
  return (
    <Waypoint onEnter={trackOnEnter} onLeave={trackOnExit} {...rest}>
      <div>{children}</div>
    </Waypoint>
  );
};

export default TrackScroll;
