import { css } from '@/style';

export const variants = {
  simple: css`
    width: 100%;
    border-bottom: 1px solid;
    border-color: palette-text;
    margin-top: md;
    margin-bottom: md;
    opacity: 0.15;
  `,
  boldStart: css`
    position: relative;
    width: 100%;
    border-bottom: 1px solid;
    border-color: palette-border;
    margin-top: md;
    margin-bottom: md;
    ::after {
      position: absolute;
      left: 0;
      width: 100%;
      max-width: 80px;
      top: -1px;
      height: 3px;
      border-radius: 1.5px;
      background-color: palette-primary;
      content: ' ';
      display: block;
    }
  `,
};
