import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

export const useStorePreviousPath = () => {
  const { asPath } = useRouter();

  const ref = useRef<string | null>(null);

  useEffect(() => {
    ref.current = asPath;
  }, [asPath]);

  return ref.current;
};
