/**
 * For setting specific px values see:
 * /support/docs/issues/rpx-transform.md
 */

import { ROOT_FONT_RATIO } from '../constants';

export default {
  button: 60 * ROOT_FONT_RATIO,
  header: 100,
  'drawer-width': 560,
  'modal-width': 560,
  'section-width-sm': 640,
  'section-width': 760,
  'section-width-lg': 980,
  'section-width-xl': 1200,
  'section-height': 300,
  'view-height': 'calc(var(--vh, 1vh) * 100)',
};
