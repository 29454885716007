import { FC } from 'react';

import { styled } from '@/style';

const Box = styled.div`
  max-width: 100%;
`;

const Placeholder: FC<{
  name: string;
}> = ({ name }) => (
  <Box>
    The component <strong>{name}</strong> has not been created yet.
  </Box>
);

export default Placeholder;
