import { VFC } from 'react';

import { z } from 'zod';

import * as Form from '@/common/Form';
import * as e from '@/elements';
import { useZodForm } from '@/lib/Form';
import { Modal } from '@/lib/Modal';
import useModals from '@/lib/Modal/context';

import { useSubscribeToNewsletter } from '../hooks/use-subscribe-to-newsletter';

const schema = z.object({
  email: z.string().email('Email is in wrong format'),
});

export const SubscribeModal: VFC = () => {
  const modal = useModals();
  const formHandler = useZodForm(schema);
  const { isSubmitting, subscribe } = useSubscribeToNewsletter();

  const onSubmit = formHandler.handleSubmit(async (form, ev) => {
    ev?.preventDefault();
    if (!isSubmitting) {
      await subscribe(form.email);
      modal.closeModal();
    }
  });

  return (
    <Modal variant='popover'>
      <e.Layout.VStack textAlign='center' mb='md'>
        <e.Text variant='h2' as='h2'>
          Subscribe to our newsletter
        </e.Text>
        <e.Text variant='body1'>
          Get monthly tips and the latest housing news by subscribing to our
          newsletter
        </e.Text>
      </e.Layout.VStack>
      <Form.Container form={formHandler} onSubmit={onSubmit}>
        <Form.Input name='email' type='email' label='Email' />
        <e.Button disabled={isSubmitting} type='submit'>
          Subscribe
        </e.Button>
      </Form.Container>
    </Modal>
  );
};
