import { ComponentProps, PropsWithChildren } from 'react';

import { FormProvider, UseFormReturn } from 'react-hook-form';

import { x } from '@/style';

function Form<TForm>({
  form,
  ...rest
}: PropsWithChildren<
  {
    form: UseFormReturn<TForm>;
  } & ComponentProps<typeof x.form>
>) {
  return (
    <FormProvider {...form}>
      <x.form {...rest} />
    </FormProvider>
  );
}

export default Form;
