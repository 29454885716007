/* eslint-disable no-console */

export const debug = (...message: Array<any>) => {
  console.debug(...message);
};
export const log = (...message: Array<any>) => {
  console.log(...message);
};
export const info = (...message: Array<any>) => {
  console.info(...message);
};
export const warn = (message: string | Error) => {
  console.warn(message);
};
export const error = (message: string | Error) => {
  console.error(message);
};
