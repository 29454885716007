import type { AppProps } from 'next/app';

import { createTheme, WuiProvider } from '@welcome-ui/core';
import Head from 'next/head';

import GlobalStyles from '@/common/GlobalStyles';
import Analytics from '@/common/analytics';
import { BlogProvider } from '@/common/blog/context';
import { SubscribeModal } from '@/common/modals/subscribe';
import { resolvePathFromSlug } from '@/common/storyblok/utils/resolve-path-from-slug';
import config from '@/config';
import { MAIN_SCROLL_CONTAINER_ID } from '@/constants';
import linkConfig from '@/data/linkConfig.json';
import { ModalProvider } from '@/lib/Modal';
import { PreviousPathProvider } from '@/lib/previous-path';
import { RedirectProvider } from '@/lib/redirect';
import { SetVhAsCssProperty } from '@/lib/scripts/set-vh-as-css-property';
import { ScrollToTopOnRouteChange } from '@/lib/scroll-to-top-on-route-change';
import CookieYes from '@/lib/snippets/cookieYes';
import Favicons from '@/lib/snippets/favicons';
import Hubspot from '@/lib/snippets/hubspot';
import SocialMeta from '@/lib/snippets/socialMeta';
import { StoryblokProvider } from '@/lib/storyblok';
import { Provider as AppStateProvider } from '@/state/AppState';
import { theme, ThemeProvider, Preflight } from '@/style';

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>{config.seo.title}</title>
        <meta content='width=device-width, initial-scale=1' name='viewport' />
        <meta content='ie=edge' httpEquiv='x-ua-compatible' />

        <Favicons tileColor={config.tileColor} name={config.seo.companyName} />

        <Hubspot id={config.hubspotId} />
        <CookieYes id={config.cookieYes} />
      </Head>
      <SocialMeta
        baseUrl={config.seo.baseUrl}
        title={config.seo.title}
        description={config.seo.description}
        image={config.seo.imageUrl}
        keywords={config.seo.keywords}
      />
      <SetVhAsCssProperty />
      <Analytics>
        <ScrollToTopOnRouteChange id={MAIN_SCROLL_CONTAINER_ID}>
          <ThemeProvider theme={theme as any}>
            <WuiProvider
              theme={createTheme(theme)}
              shouldHideFocusRingOnClick
              reactRootId='__next'
            >
              <Preflight />
              <GlobalStyles />
              <ModalProvider
                modals={[{ key: 'subscribe', Component: SubscribeModal }]}
              >
                <AppStateProvider>
                  <RedirectProvider
                    rewrites={linkConfig.rewrites}
                    redirects={linkConfig.redirects}
                  >
                    <StoryblokProvider
                      resolvePathFromSlug={resolvePathFromSlug}
                    >
                      <PreviousPathProvider>
                        <BlogProvider>
                          <Component {...pageProps} />
                        </BlogProvider>
                      </PreviousPathProvider>
                    </StoryblokProvider>
                  </RedirectProvider>
                </AppStateProvider>
              </ModalProvider>
            </WuiProvider>
          </ThemeProvider>
        </ScrollToTopOnRouteChange>
      </Analytics>
    </>
  );
}

export default App;
