import { x, StyledComponent, StyledProps } from '@/style';

const hoverStyling: StyledProps = {
  cursor: 'pointer',
  boxShadow: {
    _: 'none',
    hover: 'lg',
  },
  transform: true,
  scale: {
    _: 1,
    hover: 1.03,
  },
};

export const Card: StyledComponent<{
  disableHover?: boolean;
}> = ({ disableHover, ...rest }) => (
  <x.div
    borderRadius='lg'
    border='1px solid transparent'
    borderColor='palette-border'
    bg='brand-bright-white'
    overflow='hidden'
    transition='base'
    {...(disableHover ? {} : hoverStyling)}
    {...rest}
  />
);
