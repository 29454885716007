import type { INavStructure, INestedItem, ILinkItem } from './types.d';

import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { FlattenSimpleInterpolation } from 'styled-components';

import useModals from '@/lib/Modal/context';

import { createFooterItems } from './create-footer-items';
import { createHeaderItems } from './create-header-items';

export type { INavStructure, INestedItem, ILinkItem };

const context = createContext<{
  headerItems: INavStructure;
  footerItems: INavStructure;
  getHeaderCss: (isAtTop?: boolean) => FlattenSimpleInterpolation | null;
  setGetHeaderCss: (
    fn: (isAtTop?: boolean) => FlattenSimpleInterpolation | null
  ) => void;
}>(undefined as any);

export const Provider: FC = ({ children }) => {
  const { openModal } = useModals();
  const [{ fn: getHeaderCss }, internalSetGetHeaderCss] = useState<{
    fn: (isAtTop?: boolean) => FlattenSimpleInterpolation | null;
  }>({
    fn: () => null,
  });
  const setGetHeaderCss = useCallback(
    (fn: (isAtTop?: boolean) => FlattenSimpleInterpolation | null) => {
      internalSetGetHeaderCss({
        fn,
      });
    },
    []
  );

  const navState = useMemo(
    () => ({
      headerItems: createHeaderItems(),
      footerItems: createFooterItems({
        openModal,
      }),
    }),
    []
  );

  return (
    <context.Provider
      value={{
        ...navState,
        getHeaderCss,
        setGetHeaderCss,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default function useAppState() {
  const c = useContext(context);

  if (!c) throw new Error('Cannot retrieve app state outside of provider');
  return c;
}
