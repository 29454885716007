import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { warn } from '@/services/log';

const useRouterState = (
  paramName: string,
  {
    defaultValue = undefined,
    isBlank = (newValue: any) => !newValue,
    shallow = true,
  } = {}
): [string | undefined, (newValue?: string) => void] => {
  const { query, push } = useRouter();
  const removeValue = useCallback(() => {
    const path = new URL(window.location.href);
    path.searchParams.delete(paramName);
    const url = path.pathname + path.search;
    push(url, url, { shallow });
  }, [push, paramName, shallow]);
  const setValue = useCallback(
    (newValue?: string) => {
      if (isBlank(newValue)) removeValue();
      else {
        const path = new URL(window.location.href);
        path.searchParams.set(paramName, newValue!);
        const url = path.pathname + path.search;
        push(url, url, { shallow });
      }
    },
    [push, isBlank, removeValue, paramName, shallow]
  );

  const paramValue = query[paramName];
  let singleParamValue: string | undefined;
  if (Array.isArray(paramValue)) {
    warn(
      'Router state should only have a single param value, selecting the first'
    );
    // eslint-disable-next-line prefer-destructuring
    singleParamValue = paramValue[0];
  } else {
    singleParamValue = paramValue;
  }

  return [singleParamValue || defaultValue, setValue];
};

export default useRouterState;
