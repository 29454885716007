import { useCallback } from 'react';

export function useScrollToTop(id?: string) {
  return useCallback(
    (behavior: 'auto' | 'smooth' = 'auto') => {
      const scrollElement = id ? document.getElementById(id) || window : window;
      scrollElement.scroll({
        top: 0,
        left: 0,
        behavior,
      });
    },
    [id]
  );
}
