import { TextareaHTMLAttributes } from 'react';

import { TFormItem, withFormItem } from '@/lib/Form';
import useFormItem from '@/lib/Form/FormItemContext';

import { TextAreaContainer, TextArea, TextAreaGrabber } from './components';

import * as shared from '../shared';

const TextAreaComponent: TFormItem<TextareaHTMLAttributes<any>> = ({
  label,
  ...rest
}) => {
  const { registration } = useFormItem();

  return (
    <shared.Label>
      <shared.LabelText>{label}</shared.LabelText>
      <shared.InputBorder>
        <TextAreaContainer>
          <TextArea {...rest} {...registration} />
          <TextAreaGrabber />
        </TextAreaContainer>
      </shared.InputBorder>
      <shared.ErrorMessage />
    </shared.Label>
  );
};

export default withFormItem(TextAreaComponent);
