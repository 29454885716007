import { style } from '@xstyled/styled-components';

export interface BlankButtonProps {
  blankButton?: boolean;
}

export const blankButton = style({
  prop: 'blankButton',
  css: () => ({
    textDecoration: 'none',
    bg: 'transprent',
    outline: 'none',
    border: 'none',
    p: 0,
  }),
});
