import { withClickable } from '@/common/clickable';
import { VariantState, variantFromStates, x } from '@/style';

const shadow = '0px 1.21364px 2.42727px rgba(0, 0, 0, 0.12)';

export const Button = withClickable<{
  width?: number | string;
  state: VariantState<'selected'>;
}>(({ as, width = 'unset', state, children, ...rest }) => (
  <x.div
    as={as}
    text='button'
    blankButton
    flexCenter
    borderRadius='sm'
    transition='base'
    w={width}
    h='40px'
    px='lg'
    opacity={variantFromStates(state, {
      _: 0.7,
      hover: 1,
      selected: 1,
    })}
    bg={variantFromStates(state, {
      _: 'transparent',
      hover: 'white',
      selected: 'white',
    })}
    boxShadow={variantFromStates(state, {
      _: 'none',
      hover: shadow,
      selected: shadow,
    })}
    {...rest}
  >
    {children}
  </x.div>
));
