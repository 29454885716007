const nativeStates = ['_', 'hover', 'focus'];
type NativeStates = '_' | 'hover' | 'focus';

export type VariantState<States extends string> = Record<States, boolean>;
type Variants<States extends string> = Partial<
  Record<States | NativeStates, number | string>
>;

export const variantFromStates = <States extends string>(
  states: VariantState<States>,
  variants: Variants<States>
) =>
  Object.keys(variants).reduce(
    ((acc: Variants<States>, curr: States) => {
      if (nativeStates.includes(curr)) {
        return {
          ...acc,
          [curr]: variants[curr],
        };
      }
      if (states[curr]) {
        return {
          _: variants[curr],
        };
      }
      return acc;
    }) as any,
    {}
  );
