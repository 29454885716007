import { Layout } from '@/elements';
import { Form } from '@/lib/Form';

const Container: typeof Form = ({ children, ...rest }) => (
  <Form {...rest}>
    <Layout.VStack>{children}</Layout.VStack>
  </Form>
);

export default Container;
