import { ComponentProps, FC } from 'react';

import { PathFromSlugProvider } from './path-from-slug-resolver';

export const StoryblokProvider: FC<
  ComponentProps<typeof PathFromSlugProvider>
> = ({ resolvePathFromSlug, children }) => (
  <PathFromSlugProvider resolvePathFromSlug={resolvePathFromSlug}>
    {children}
  </PathFromSlugProvider>
);
