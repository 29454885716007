import type { TTrackFn } from './types';

import { FC, createContext, useContext } from 'react';

const context = createContext<{
  track: TTrackFn;
  isInited: boolean;
}>({
  track: null,
  isInited: false,
} as any);

export const Provider: FC<{
  track: TTrackFn;
  isInited: boolean;
}> = ({ children, track, isInited }) => (
  <context.Provider
    value={{
      track,
      isInited,
    }}
  >
    {children}
  </context.Provider>
);

const useTrack = () => {
  const trackContext = useContext(context);
  if (!trackContext)
    throw new Error('track can only be retrieved in track provider');
  return trackContext;
};

export default useTrack;
