import { defaultTheme, rpxTransformers } from '@xstyled/styled-components';

import colors from './colors';
import fontSizes from './fontSizes';
import fonts from './fonts';
import lineHeights from './lineHeights';
import radii from './radii';
import screens from './screens';
import shadows from './shadows';
import sizes from './sizes';
import space from './space';
import texts from './texts';
import transitions from './transitions';
import zIndices from './zIndices';

export const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    ...colors,
  },
  fonts: {
    ...defaultTheme.fonts,
    ...fonts,
  },
  fontSizes: {
    ...defaultTheme.fontSizes,
    ...fontSizes,
  },
  fontFaces: {},
  lineHeights: {
    ...defaultTheme.lineHeights,
    ...lineHeights,
  },
  radii: {
    ...defaultTheme.radii,
    ...radii,
  },
  screens: screens(defaultTheme.screens),
  shadows: {
    ...defaultTheme.shadows,
    ...shadows,
  },
  sizes: {
    ...defaultTheme.sizes,
    ...sizes,
  },
  space: {
    ...defaultTheme.space,
    ...space,
  },
  texts: {
    ...defaultTheme.texts,
    ...texts,
  },
  transformers: {
    ...rpxTransformers,
  },
  transitions: {
    ...defaultTheme.transitions,
    ...transitions,
  },
  zIndices: {
    ...zIndices,
  },
};
