import { style } from '@xstyled/styled-components';

export interface AbsoluteFullProps {
  absoluteFull?: boolean;
}

export const absoluteFull = style({
  prop: 'absoluteFull',
  css: () => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
});
