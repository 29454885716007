import { styled } from '@/style';

// eslint-disable-next-line import/prefer-default-export
export const NonModalContainer = styled.divBox<{
  $isModalOpen: boolean;
}>`
  transition: base;
  height: 100vh;
  width: 100%;
  ${({ $isModalOpen }) =>
    $isModalOpen ? `filter: blur(3px);` : `filter: blur(0);`}
`;
