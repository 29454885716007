const scale = {
  xxxl: '1.24',
  xxl: '1.3',
  xl: '1.4',
  lg: '1.6',
  md: '1.5',
  sm: '1.5',
  xs: '1.8',
  xxs: '1.8',
};

export default {
  ...scale,
  h1: scale.xxxl,
  h2: scale.xxl,
  h3: scale.xl,
  h4: scale.md,
  subtitle1: scale.lg,
  body1: scale.md,
  body2: scale.sm,
  meta1: scale.xs,
  button: scale.md,
};
