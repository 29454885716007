import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormProps, useForm } from 'react-hook-form';
import { z } from 'zod';

const useZodForm = <
  TSchemaShape extends z.ZodRawShape,
  TForm = z.infer<z.ZodObject<TSchemaShape>>
>(
  schema: z.ZodObject<TSchemaShape>,
  config: Omit<UseFormProps<TForm>, 'resolver'> = {}
) =>
  useForm({
    ...config,
    resolver: zodResolver(schema),
  });

export default useZodForm;
