import { useCallback, useContext } from 'react';

import { context } from './context';

export const useResolvePathWithRedirects = () => {
  const { redirects, rewrites } = useContext(context) || {
    redirects: [],
    rewrites: [],
  };

  const resolvePathWithRedirects = useCallback((path: string) => {
    if (redirects[path]) return redirects[path].destination;
    if (rewrites[path]) return rewrites[path].destination;
    return path;
  }, []);

  return {
    resolvePathWithRedirects,
  };
};
