import {
  RenderParams,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_LINK,
  MARK_STRIKE,
  MARK_UNDERLINE,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  NODE_OL,
  NODE_UL,
  NODE_LI,
  NODE_HR,
} from 'storyblok-rich-text-react-renderer';

import type { TResolver, TConfig, TBloks } from '@/lib/storyblok';

const createRenderParams = (
  resolver: TResolver,
  bloks: TBloks,
  config: TConfig
): RenderParams => ({
  markResolvers: {
    [MARK_LINK]: (
      children,
      { href, target }: { href: string; target: string }
    ) => (
      <resolver.Link href={href} target={target}>
        {children}
      </resolver.Link>
    ),
    [MARK_BOLD]: (children) => <resolver.Bold>{children}</resolver.Bold>,
    [MARK_ITALIC]: (children) => <resolver.Italic>{children}</resolver.Italic>,
    [MARK_UNDERLINE]: (children) => (
      <resolver.Underline>{children}</resolver.Underline>
    ),
    [MARK_STRIKE]: (children) => <resolver.Strike>{children}</resolver.Strike>,
  },
  nodeResolvers: {
    [NODE_HEADING]: (children, { level }) => {
      switch (level) {
        case 1:
          return <resolver.H1>{children}</resolver.H1>;
        case 2:
          return <resolver.H2>{children}</resolver.H2>;
        case 3:
          return <resolver.H3>{children}</resolver.H3>;
        case 4:
          return <resolver.H4>{children}</resolver.H4>;
        case 5:
          return <resolver.H5>{children}</resolver.H5>;
        // case 6:
        //   return <Text variant='h6'>{children}</Text>;
        default:
          return <resolver.Paragraph>{children}</resolver.Paragraph>;
      }
    },
    [NODE_IMAGE]: (children, { src, alt, title }) => (
      <resolver.Image alt={alt} src={src} title={title} />
    ),
    [NODE_PARAGRAPH]: (children) => (
      <resolver.Paragraph>{children}</resolver.Paragraph>
    ),
    [NODE_OL]: (children) => <resolver.Ol>{children}</resolver.Ol>,
    [NODE_UL]: (children) => <resolver.Ul>{children}</resolver.Ul>,
    [NODE_LI]: (children) => <resolver.Li>{children}</resolver.Li>,
    [NODE_HR]: (children) => <resolver.Hr>{children}</resolver.Hr>,
  },
  blokResolvers: {
    ...(bloks || {}),
  },
  defaultBlokResolver: (name: string) => {
    if (config.env === 'development') {
      return <config.Placeholder name={name} />;
    }

    config.log.warn(new Error('Storyblok component has not been implemented'));
    return null;
  },
});

export default createRenderParams;
