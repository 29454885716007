import { createContext, FC, useContext, useState } from 'react';

const context = createContext<{
  isEnabled: boolean;
  setIsEnabled: (is: boolean) => void;
}>(undefined as any);

export const ScrollToTopOnRouteChangeProvider: FC = ({ children }) => {
  const [isEnabled, setIsEnabled] = useState(true);
  return (
    <context.Provider
      value={{
        isEnabled,
        setIsEnabled,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useConfig = () => {
  const config = useContext(context);
  if (!config)
    throw new Error(
      'Cannot use scroll to top on route change config outside of provider'
    );
  return config;
};
