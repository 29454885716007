import { FC, useCallback, useEffect } from 'react';

import useModals from './context';
import * as variants from './variants';

export const BasicModal: FC<{
  onClose?: () => void;
  removeCloseButton?: boolean;
  variant?: keyof typeof variants;
}> = ({
  onClose,
  removeCloseButton,
  variant: variantName = 'fullScreen',
  children,
}) => {
  const { closeModal } = useModals();
  // eslint-disable-next-line import/namespace
  const variant = variants[variantName];

  const doClose = useCallback(() => {
    closeModal();
    onClose?.();
  }, [onClose, closeModal]);

  useEffect(() => {
    function keyUpHandler(event: KeyboardEvent) {
      if (event.keyCode === 27) {
        doClose();
      }
    }
    window.addEventListener('keyup', keyUpHandler, false);
    return () => window.removeEventListener('keyup', keyUpHandler);
  }, [doClose]);

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    const body = document.getElementsByTagName('body')[0];
    html.classList.add('disable-scroll');
    body.classList.add('disable-scroll');
    return () => {
      html.classList.remove('disable-scroll');
      body.classList.remove('disable-scroll');
    };
  }, []);

  return (
    <variant.Container
      animate='visible'
      exit='hidden'
      initial='hidden'
      variants={variant.animations.container}
    >
      <variant.Overlay onClick={doClose} />
      <variant.Content variants={variant.animations.content}>
        {!removeCloseButton && <variant.CloseButton onClick={doClose} />}
        {children}
      </variant.Content>
    </variant.Container>
  );
};

export default BasicModal;
