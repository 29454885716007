import { useMemo } from 'react';

import { useRouter } from 'next/router';
import parseUrl from 'url-parse';

export const useCreatePathWithQueryParams = (path?: string) => {
  const { asPath: currentPath } = useRouter();

  const fullPath = useMemo(() => {
    const url = parseUrl(currentPath, true);
    if (path) {
      const [query, hash] = path.split('#');
      url.set('query', query);
      if (hash) {
        url.set('hash', hash);
      }
      return url.toString();
    }
    return path;
  }, [path, currentPath]);

  return fullPath;
};
