import { styled, css } from '@/style';

const olVariants = {
  blank: css``,
  bold: css`
    list-style-type: decimal;
    padding-left: 1.5rem;
    & > li {
      padding-left: 0.25rem;
      &::marker {
        font-weight: 700;
      }
    }
  `,
  colored: css`
    list-style-type: decimal;
    padding-left: 1.5rem;
    & > li {
      padding-left: 0.25rem;
      &::marker {
        font-weight: 700;
        color: palette-primary;
      }
    }
  `,
};
export const Ol = styled.ol<{
  variant?: keyof typeof olVariants;
}>`
  ${({ variant = 'bold' }) => olVariants[variant]}
`;

const ulVariants = {
  blank: css``,
  diamond: css`
    padding-left: 2rem;
    & > li {
      position: relative;
      :after {
        content: ' ';
        height: 0.4rem;
        width: 0.4rem;
        background-color: var(--component-color);
        display: block;
        position: absolute;
        border-radius: 0.1rem;
        transform: rotate(45deg);
        top: 0.5rem;
        right: 100%;
        margin-right: 1rem;
      }
    }
  `,
};

export const Ul = styled.ul<{
  variant?: keyof typeof ulVariants;
}>`
  --component-color: ${({ theme }) => theme.colors['palette-primary']};

  ${({ variant = 'diamond' }) => ulVariants[variant]}
`;
export const Li = styled.li``;
