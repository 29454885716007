import { FC, createContext, useContext, useCallback } from 'react';

import { AnimatePresence } from 'framer-motion';

import useRouterState from '@/lib/hooks/useRouterState';

import { NonModalContainer } from './components';
import DisableScrollGlobalStyle from './disableScrollGlobalStyle';

import { useTrack } from '../track';

const context = createContext<{
  openModal: (modalName: string) => void;
  closeModal: () => void;
  activeModal: undefined | string;
  isOpen: boolean;
}>({
  openModal: undefined,
  closeModal: undefined,
  activeModal: undefined,
  isOpen: false,
} as any);

export const Provider: FC<{
  modalParam?: string;
  modals: Array<{ key: string; Component: FC }>;
}> = ({ modalParam = 'm', children, modals = [] }) => {
  const [activeModal, setModal] = useRouterState(modalParam);
  const { track } = useTrack();
  const isOpen = !!activeModal;
  const openModal = useCallback(
    (name: string) => {
      setModal(name);
      track('Modal Open', name);
    },
    [setModal, track]
  );
  const closeModal = useCallback(() => {
    setModal();
    track('Modal Closed');
  }, [setModal, track]);
  const activeModalComponent = modals.find((m) => m.key === activeModal);

  return (
    <context.Provider value={{ openModal, closeModal, activeModal, isOpen }}>
      <DisableScrollGlobalStyle />
      <NonModalContainer $isModalOpen={isOpen}>{children}</NonModalContainer>
      <AnimatePresence exitBeforeEnter>
        {activeModalComponent ? (
          <activeModalComponent.Component key={activeModalComponent.key} />
        ) : null}
      </AnimatePresence>
    </context.Provider>
  );
};

export default function useModals() {
  const modalsContext = useContext(context);
  if (!modalsContext)
    throw new Error('Cannot use Modals context outside of Modals provider');
  return modalsContext;
}
