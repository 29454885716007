import { isFrontend } from './utils/is-frontend';

// eslint-disable-next-line import/no-mutable-exports
let dataLayer: Array<any> | null = null;
if (isFrontend) {
  window.dataLayer = window.dataLayer || [];
  dataLayer = window.dataLayer;
}

export { dataLayer };
