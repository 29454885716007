import { ComponentProps, ComponentRef, forwardRef } from 'react';

import { styled, x } from '@/style';

type Dir = 'row' | 'column' | 'row-reverse' | 'column-reverse';

type TStack = ComponentProps<typeof x.div> & {
  /**
   * @deprecated Use `flexDirection` instead
   */
  dir?: Dir;
  /**
   * @deprecated Use `gap` instead
   */
  spacing?: string;
};

export const Stack = forwardRef<ComponentRef<typeof x.div>, TStack>(
  ({ dir = 'row', spacing = 'md', flexDirection, gap, ...rest }, ref) => (
    <x.div
      ref={ref as any}
      display='flex'
      flexDirection={flexDirection || dir}
      gap={gap || spacing}
      {...rest}
    />
  )
);

export const VStack = forwardRef<
  ComponentRef<typeof Stack>,
  ComponentProps<typeof Stack>
>(({ dir = 'column', ...rest }, ref) => (
  <Stack ref={ref} dir={dir} {...rest} />
));

export const HStack = forwardRef<
  ComponentRef<typeof Stack>,
  ComponentProps<typeof Stack>
>(({ dir = 'row', ...rest }, ref) => <Stack ref={ref} dir={dir} {...rest} />);

export const Grid = styled.divBox<{
  $columnCount: number;
  $minColumnWidth?: number;
}>`
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  grid-template-columns: repeat(${({ $columnCount }) => $columnCount}, 1fr);
  column-gap: lg;
  row-gap: md;
  max-width: 100%;
`;

// eslint-disable-next-line import/prefer-default-export
export const Box = styled.divBox``;
