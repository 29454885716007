import { useCallback } from 'react';

import { useGetContextOrError } from '@/lib/hooks/use-get-context-or-error';

import { context } from './context';

import { useResolvePathFromSlug } from '../path-from-slug-resolver';

export const useGetPathFromPageId = () => {
  const { linkMap } = useGetContextOrError(context, 'PageIdToPath');
  const { resolvePathFromSlug } = useResolvePathFromSlug();

  const getPathFromPageId = useCallback((pageId: string) => {
    const link = linkMap[pageId];
    if (!link) return null;
    return resolvePathFromSlug(link?.real_path);
  }, []);

  return {
    getPathFromPageId,
  };
};
