import { FC, createContext, useContext, useMemo } from 'react';

import { motion } from 'framer-motion';
import {
  RegisterOptions,
  useFormContext,
  UseFormRegisterReturn,
  FieldError,
} from 'react-hook-form';

type TFormItemContext<TValue> = {
  name: string;
  options?: RegisterOptions;
  registration: UseFormRegisterReturn;
  value: TValue;
  error: FieldError | undefined;
  isDirty: boolean;
};

const FormItemContext = createContext<TFormItemContext<any>>(undefined as any);

export const Provider: FC<{
  name: string;
  options?: RegisterOptions;
}> = ({ children, name, options }) => {
  const formContext = useFormContext();
  const registration = formContext.register(name, options);
  const itemValue = formContext.watch(name);
  const error = formContext.formState.errors[name];
  const isDirty = formContext.formState.dirtyFields[name];
  const value = useMemo(
    () => ({
      name,
      options,
      registration,
      value: itemValue,
      error,
      isDirty,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemValue, error, isDirty]
  );
  return (
    <FormItemContext.Provider value={value}>
      <motion.div animate={error ? 'error' : 'normal'}>{children}</motion.div>
    </FormItemContext.Provider>
  );
};

export default function useFormItem<TValue = string>() {
  return useContext(FormItemContext) as TFormItemContext<TValue>;
}
