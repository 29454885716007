import { FC } from 'react';

import { Button } from './button';
import { Container } from './container';

interface Base {
  type: 'button' | 'link';
  id: string;
  label: string;
}

interface TButton extends Base {
  type: 'button';
  onClick: () => void;
}
interface Link extends Base {
  type: 'link';
  href: string;
}
type Clickable = Link | TButton;

export const RadioButtonGroup: FC<{
  selectedId: string;
  items: Array<Clickable>;
  buttonWidth?: any;
}> = ({ selectedId, items, buttonWidth }) => (
  <Container>
    {items.map((item) => {
      const commonProps = {
        key: item.id,
        children: item.label,
        w: buttonWidth,
        whiteSpace: 'noWrap',
        px: 'md',
        state: {
          selected: item.id === selectedId,
        },
      };
      switch (item.type) {
        case 'button':
          return (
            <Button
              {...commonProps}
              as='button'
              type='button'
              onClick={item.onClick}
            />
          );
        case 'link':
          return <Button {...commonProps} as='link' href={item.href} />;
        default:
          throw new Error(`Unrecoginsed item type ${(item as Clickable).type}`);
      }
    })}
  </Container>
);
