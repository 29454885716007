import { CSSProperties, style } from '@xstyled/styled-components';

export interface AspectRatioProps {
  aspectRatio?: CSSProperties['aspectRatio'];
}

export const aspectRatio = style({
  prop: 'aspectRatio',
  css: (value) => ({
    aspectRatio: value,
  }),
});
