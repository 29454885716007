import { UnstyledLink } from '@/elements/link';

import { ClickableOption } from './types/clickable-option';

export const getAsComponent = (as: ClickableOption) => {
  switch (as) {
    case 'button':
      return 'button';
    case 'link':
      return UnstyledLink;
    case 'none':
      return 'div';
    default:
      throw new Error(`Component ${as} not recoginsed as clickable`);
  }
};
