import { css } from '@/style';

import { variants as ButtonVariants } from '../button';

export const linkVariants = {
  ...ButtonVariants,
  primary: css``,
  blank: css`
    text-decoration: none;
    text-align: left;
  `,
  listItem: css`
    background-color: transparent;
    text-align: center;
    width: 100%;
    transition: slow;
    display: block;
    padding: sm;
    :hover {
      transition: base;
      background-color: palette-primary;
    }
  `,
  hoverColor: css`
    text-decoration: none;
    color: currentColor;
    transition: base;
    :hover {
      color: palette-primary;
    }
  `,
};
