import type { StoryblokComponent } from 'storyblok';

import React, { ComponentProps } from 'react';

import config from '@/config';
import { warn } from '@/services/log';

import DefaultPlaceholder from './components/placeholder';

type TProps<TContent extends StoryblokComponent<any> = any> =
  ComponentProps<TContent>;

export default function createDynamicComponent<
  TComponent extends StoryblokComponent<any>
>(
  components: {
    [name: string]: TComponent;
  },
  Placeholder = DefaultPlaceholder
) {
  return ({
    content,
    story,
    showPlaceholder = config.env === 'development',
  }: TProps<TComponent> & {
    showPlaceholder?: boolean;
  }) => {
    if (typeof components[content.component] !== 'undefined') {
      const Component = components[content.component] as any;
      return <Component content={content} story={story} />;
    }
    if (showPlaceholder) {
      return <Placeholder name={content.component} />;
    }

    warn(new Error('Storyblok component has not been implemented'));
    return null;
  };
}
