import Script from 'next/script';

export const SetVhAsCssProperty = () => (
  <Script id='set-vh-as-css-property'>
    {`
      function setVh() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', \`\${vh}px\`);
      }
    
      setVh();
      window.addEventListener('resize', () => {
        setVh();
      });
    `}
  </Script>
);
