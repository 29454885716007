const mont = 'Mont, sans-serif';
const sfUi = 'SF UI Text, sans-serif';
export default {
  base: mont,
  sans: sfUi,
  headings: mont,
  texts: mont,
  mont,
  sfUi,
};
