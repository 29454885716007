import mapValuesDeep from 'deepdash/mapValuesDeep';

import { ContentIdentifier } from './utils/content-identifier';

export const mapContent = (
  content: any,
  fn: (value: any, identifier: ContentIdentifier) => any
) =>
  mapValuesDeep({ ...content }, (value, _key, _parent, x) => {
    const newValue = fn(value, new ContentIdentifier(value, x));
    if (newValue !== value) {
      (x as any).skipChildren(true);
      return newValue;
    }
    return value;
  });
