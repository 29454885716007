import type { INavStructure } from './types.d';

export const createHeaderItems = (): INavStructure => [
  {
    id: 'services',
    label: 'Services',
    type: 'nested',
    ariaLabel: 'Services',
    trackName: 'nav.services',
    items: [
      {
        type: 'link',
        id: 'services',
        label: 'All Services',
        href: '/services',
        trackName: 'nav.services.all',
      },
      {
        type: 'link',
        id: 'kyc',
        label: 'KYC for Estate Agents',
        href: '/services#kyc',
        trackName: 'nav.services.kyc',
      },
      {
        type: 'link',
        id: 'offer-pack',
        label: 'Offer Pack',
        href: '/services#offer-pack',
        trackName: 'nav.services.offer',
      },
      {
        type: 'link',
        id: 'sales-pack',
        label: 'Sales Pack',
        href: '/services#sales-pack',
        trackName: 'nav.services.sales',
      },
    ],
  },
  {
    id: 'who-we-help',
    label: 'Who we help',
    type: 'nested',
    ariaLabel: 'Help for',
    trackName: 'nav.who',
    items: [
      {
        type: 'link',
        id: 'for-buyers',
        label: 'Buyers',
        href: '/for/buyers',
        trackName: 'nav.who.buyers',
      },
      {
        type: 'link',
        id: 'for-sellers',
        label: 'Sellers',
        href: '/for/sellers',
        trackName: 'nav.who.sellers',
      },
      {
        type: 'link',
        id: 'for-ea',
        label: 'Estate Agents',
        href: '/for/estate-agents',
        trackName: 'nav.who.estate-agents',
      },
    ],
  },
  {
    id: 'learning',
    label: 'Learning',
    type: 'nested',
    ariaLabel: 'Learning',
    trackName: 'nav.learning',
    items: [
      {
        type: 'link',
        id: 'learning.buyer',
        label: 'For Buyers',
        href: '/learn/buyer',
        trackName: 'nav.learning.buyer',
      },
      {
        type: 'link',
        id: 'learning.seller',
        label: 'For Sellers',
        href: '/learn/seller',
        trackName: 'nav.learning.seller',
      },
    ],
  },
];
