import { FC } from 'react';

import config from '@/config';
import { ActiveCampaign } from '@/lib/snippets/activeCampaign';
import { Mouseflow } from '@/lib/snippets/mouseflow';
import { Vwo } from '@/lib/snippets/vwo';
import { TrackProvider, TrackRoute } from '@/lib/track';
import useAnalyticsInit from '@/services/analytics';

const Analytics: FC = ({ children }) => {
  const { track, isInited } = useAnalyticsInit();

  return (
    <>
      <TrackProvider track={track} isInited={isInited}>
        <TrackRoute>{children}</TrackRoute>
      </TrackProvider>
      <ActiveCampaign id='226178463' />
      <Mouseflow isActive={config.mouseflow.isActive} />
      <Vwo isActive={config.vwo.isActive} />
    </>
  );
};

export default Analytics;
