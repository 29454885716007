import type { StoriesResult } from 'management-storyblok';

import StoryblokClient from 'storyblok-js-client';

export default class ManagmentClient {
  private client: StoryblokClient;

  private space: string;

  constructor(oauthToken: string, space: string) {
    this.space = space;
    this.client = new StoryblokClient({
      oauthToken,
    });
  }

  getAllStories(folder: string, query = {}) {
    return this.client.get(`spaces/${this.space}/stories/`, {
      story_only: true,
      starts_with: folder,
      is_published: true,
      ...query,
    }) as Promise<StoriesResult>;
  }
}
