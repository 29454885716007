import type { SbEditableContent } from 'storyblok';

import trim from 'lodash/trim';

// eslint-disable-next-line import/prefer-default-export

export const removePrefixFromSlug = (slug: string, prefix: string) => {
  const cleanSlug = trim(slug, '/');
  const cleanPrefix = trim(prefix, '/');
  if (cleanSlug.startsWith(cleanPrefix)) {
    if (cleanSlug === cleanPrefix) return '';
    return cleanSlug.slice(cleanPrefix.length + 1);
  }
  return cleanSlug;
};

export const createSlugHelper = ({
  prefixToRemove,
}: { prefixToRemove?: string } = {}) => {
  const cleanedPrefix = trim(prefixToRemove, '/');
  const helper = {
    toSlug: (url: string | string[] | undefined) => {
      const urlText = Array.isArray(url)
        ? url.filter((param) => !!param).join('/')
        : url;
      const cleanUrlText = trim(urlText, '/');
      return cleanUrlText ? `${cleanedPrefix}/${cleanUrlText}` : cleanedPrefix;
    },
    toUrl: (slug: string) => {
      const cleanedSlug = trim(slug, '/');
      if (cleanedPrefix)
        return `/${removePrefixFromSlug(cleanedSlug, cleanedPrefix)}`;
      return `/${cleanedSlug}`;
    },
    toUrlArray: (slug: string) =>
      helper
        .toUrl(slug)
        .split('/')
        .filter((param) => !!param),
  };
  return helper;
};

export const getSingleBlok = <T>(
  component: Array<SbEditableContent<T>>,
  { isRequired = true } = {}
) => {
  if (!component || component.length === 0) {
    // eslint-disable-next-line no-console
    if (isRequired) console.error('Required blok was not provided');
    return null;
  }

  if (component.length > 1)
    // eslint-disable-next-line no-console
    console.error('Array should be single but had multiple bloks');

  return component[0];
};
