import { styled } from '@/style';

import { UnstyledLink } from './unstyled-link';
import { linkVariants } from './variants';

export const Link = styled(UnstyledLink)<{
  variant?: keyof typeof linkVariants;
  version?: 'primary' | 'secondary';
  target?: string;
}>`
  background: transparent;
  text-decoration: none;
  color: inherit;
  text-align: center;
  :focus {
    color: inherit;
  }
  ${({ variant }) => (variant ? linkVariants[variant] : linkVariants.primary)}
`;
