export default function Favicon({
  location = '/favicon',
  tileColor,
  name,
}: {
  location?: string;
  tileColor: string;
  name: string;
}) {
  return (
    <>
      <link
        rel='apple-touch-icon-precomposed'
        sizes='57x57'
        href={`${location}/apple-touch-icon-57x57.png`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='114x114'
        href={`${location}/apple-touch-icon-114x114.png`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='72x72'
        href={`${location}/apple-touch-icon-72x72.png`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='144x144'
        href={`${location}/apple-touch-icon-144x144.png`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='60x60'
        href={`${location}/apple-touch-icon-60x60.png`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='120x120'
        href={`${location}/apple-touch-icon-120x120.png`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='76x76'
        href={`${location}/apple-touch-icon-76x76.png`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='152x152'
        href={`${location}/apple-touch-icon-152x152.png`}
      />
      <link
        rel='icon'
        type='image/png'
        href={`${location}/favicon-196x196.png`}
        sizes='196x196'
      />
      <link
        rel='icon'
        type='image/png'
        href={`${location}/favicon-96x96.png`}
        sizes='96x96'
      />
      <link
        rel='icon'
        type='image/png'
        href={`${location}/favicon-32x32.png`}
        sizes='32x32'
      />
      <link
        rel='icon'
        type='image/png'
        href={`${location}/favicon-16x16.png`}
        sizes='16x16'
      />
      <link
        rel='icon'
        type='image/png'
        href={`${location}/favicon-128.png`}
        sizes='128x128'
      />
      <meta name='application-name' content={name} />
      <meta name='msapplication-TileColor' content={tileColor} />
      <meta
        name='msapplication-TileImage'
        content={`${location}/mstile-144x144.png`}
      />
      <meta
        name='msapplication-square70x70logo'
        content={`${location}/mstile-70x70.png`}
      />
      <meta
        name='msapplication-square150x150logo'
        content={`${location}/mstile-150x150.png`}
      />
      <meta
        name='msapplication-wide310x150logo'
        content={`${location}/mstile-310x150.png`}
      />
      <meta
        name='msapplication-square310x310logo'
        content={`${location}/mstile-310x310.png`}
      />
    </>
  );
}
