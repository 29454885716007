import { StyledProps } from '../types';

export const footerButtonCss: StyledProps = {
  outline: 'none',
  border: 'none',
  w: '100%',
  bg: 'palette-bg',
  borderTop: '1px solid transparent',
  borderTopColor: 'palette-border',
  boxShadow: '0px -2px 2px rgba(0, 0, 0, 0.04)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: 'button',
  color: {
    _: 'palette-text',
    hover: 'palette-primary',
  } as any,
};
