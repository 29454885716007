import { FC, ComponentProps } from 'react';

import PreviewAlert from './components/previewAlert';
import { PathFromPageIdProvider } from './path-from-page-id';

export const StoryblokPage: FC<
  {
    preview: boolean;
  } & ComponentProps<typeof PathFromPageIdProvider>
> = ({ preview, children, ...rest }) => (
  <>
    <PathFromPageIdProvider {...rest}>
      {children}
      <PreviewAlert preview={preview} />
    </PathFromPageIdProvider>
  </>
);
