import {
  StyleGenerator,
  compose,
  system,
  createCss,
  SystemProps,
} from '@xstyled/styled-components';

import { absoluteFull, AbsoluteFullProps } from './absolute-full';
import { aspectRatio, AspectRatioProps } from './aspect-ratio';
import { blankButton, BlankButtonProps } from './blank-button';
import { fixedFull, FixedFullProps } from './fixed-full';
import { flexCenter, FlexCenterProps } from './flex-center';

export const { css, styled, x, createGlobalStyle } = createCss<
  StyleGenerator<
    SystemProps &
      AbsoluteFullProps &
      AspectRatioProps &
      BlankButtonProps &
      FixedFullProps &
      FlexCenterProps
  >
>(
  compose(system, absoluteFull, aspectRatio, blankButton, fixedFull, flexCenter)
);
