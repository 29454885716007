import { FC } from 'react';

import { HStack } from '@/elements/layout';
import { x } from '@/style';

export const Container: FC = ({ children }) => (
  <x.div display='inline-block' w='100%'>
    <HStack
      bg='palette-subtle'
      boxShadow='inset 0px 1.21364px 2.42727px rgba(0, 0, 0, 0.08)'
      borderRadius='sm'
      spacing='xs'
      p='xs'
      w='100%'
    >
      {children}
    </HStack>
  </x.div>
);
