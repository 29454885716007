import type { INavStructure } from './types.d';

export const createFooterItems = ({
  openModal,
}: {
  openModal: (name: string) => void;
}): INavStructure => [
  {
    id: 'more',
    label: 'More',
    type: 'nested',
    ariaLabel: 'More',
    trackName: 'footer.more',
    items: [
      {
        type: 'link',
        id: 'our-story',
        label: 'Our Story',
        href: '/our/story',
        trackName: 'footer.our.story',
      },
      {
        type: 'button',
        id: 'subscribe',
        label: 'Subscribe to Our Newsletter',
        onClick: () => openModal('subscribe'),
        trackName: 'footer.more.subscribe',
      },
      // {
      //   label: 'Our Mission',
      //   href: '/our/mission',
      //   trackName: 'footer.our.mission',
      // },
    ],
  },
  {
    type: 'nested',
    ariaLabel: 'Resources',
    id: 'resources',
    label: 'Resources',
    items: [
      {
        type: 'link',
        id: 'resources.help',
        label: 'Helpdesk',
        href: 'https://housecure-helpdesk.super.site',
        target: '__blank',
        trackName: 'resources.helpdesk',
      },
      {
        type: 'link',
        id: 'about-partners',
        label: 'Why Use Our Partners',
        href: '/resources/our-partners',
        trackName: 'resources.partner.about-partners',
      },
      {
        type: 'link',
        id: 'partner-verification',
        label: 'Our Partner Verification Process',
        href: '/resources/partner-verification',
        trackName: 'resources.partner.verification',
      },
      {
        type: 'link',
        id: 'referral-fees',
        label: 'Referral Fees',
        href: '/resources/referral-fees',
        trackName: 'resources.partner.referral-fees',
      },
    ],
  },
  {
    id: 'legal',
    label: 'Legal',
    type: 'nested',
    ariaLabel: 'Legal',
    trackName: 'footer.legal',
    items: [
      {
        type: 'link',
        id: 't-and-c',
        label: 'Terms & Conditions',
        href: '/legals/terms-and-conditions',
        target: '_blank',
        trackName: 'footer.legal.terms',
      },
      {
        type: 'link',
        id: 'privacy',
        label: 'Privacy Policy',
        href: '/legals/privacy-policy',
        target: '_blank',
        trackName: 'footer.legal.pp',
      },
      {
        type: 'link',
        id: 'cookie',
        label: 'Cookies Policy',
        href: '/legals/cookie-policy',
        target: '_blank',
        trackName: 'footer.legal.cookies',
      },
      {
        type: 'link',
        id: 'data-protection',
        label: 'Data Protection Policy',
        href: '/legals/data-protection-policy',
        target: '_blank',
        trackName: 'footer.legal.dpp',
      },
      {
        type: 'link',
        id: 'complaints',
        label: 'Complaints Procedure',
        href: '/legals/complaints-procedure',
        target: '_blank',
        trackName: 'footer.legal.complaints',
      },
    ],
  },
  {
    id: 'support',
    label: 'Support',
    type: 'nested',
    ariaLabel: 'Support',
    trackName: 'footer.support',
    items: [
      // {
      //   label: 'Help Center',
      //   href: '/read/faqs',
      //   trackName: 'footer.read.faqs',
      // },
      // {
      //   type: 'link',
      //   id: 'roadmap',
      //   label: 'Feature Roadmap',
      //   href: '/feature/roadmap',
      //   target: '_blank',
      //   trackName: 'footer.feature.roadmap',
      // },
      // {
      //   type: 'link',
      //   id: 'feature-request',
      //   label: 'Feature Request',
      //   href: '/feature/request',
      //   target: '_blank',
      //   trackName: 'footer.feature.request',
      // },
      // {
      //   label: 'Media Enquiries',
      //   href: '/contact/us',
      //   trackName: 'footer.contact.media',
      // },
      {
        type: 'link',
        id: 'contact-us',
        label: 'Contact Us',
        href: '/contact/us',
        trackName: 'footer.contact.us',
      },
    ],
  },
];
