/* eslint-disable camelcase */
import { FC } from 'react';

import { context } from './context';

import { TLink } from '../types.d';

export const PathFromPageIdProvider: FC<{
  linkMap: Record<string, TLink>;
}> = ({ linkMap, children }) => (
  <context.Provider
    value={{
      linkMap,
    }}
  >
    {' '}
    {children}
  </context.Provider>
);
