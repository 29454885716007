import { Fragment } from 'react';

import type { TResolver } from '@/lib/storyblok';

const resolver: TResolver = {
  Link: Fragment,
  Bold: Fragment,
  Italic: Fragment,
  Strike: Fragment,
  Underline: Fragment,
  H1: Fragment,
  H2: Fragment,
  H3: Fragment,
  H4: Fragment,
  H5: Fragment,
  Paragraph: Fragment,
  Image: Fragment,
  Ol: Fragment,
  Ul: Fragment,
  Li: Fragment,
  Hr: Fragment,
};

export default resolver;
