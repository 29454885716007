import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';

import { useFormItem } from '@/lib/Form';

import * as c from './components';

const ErrorMessage: FC = () => {
  const { error } = useFormItem();
  return (
    <AnimatePresence>
      {error ? (
        <c.ErrorContainer
          key='error-container'
          initial={{
            opacity: 0,
            height: 0,
            x: 10,
          }}
          animate={{
            opacity: 1,
            height: 'auto',
            x: 0,
          }}
          exit={{
            opacity: 0,
            height: 0,
            x: 10,
          }}
          transition={{ type: 'tween', ease: 'easeOut', duration: 0.3 }}
        >
          {error.message}
        </c.ErrorContainer>
      ) : null}
    </AnimatePresence>
  );
};

export default ErrorMessage;
