import { useCallback } from 'react';

import useOnRouteChange from '@/lib/hooks/use-on-route-change';
import { useScrollToTop } from '@/lib/hooks/use-scroll-to-top';

import { useConfig } from './config-context';

export default function useScrollToTopOnRouteChange(id?: string) {
  const { isEnabled } = useConfig();
  const scrollToTop = useScrollToTop(id);
  const scrollToTopWhenEnabled = useCallback(() => {
    if (isEnabled) scrollToTop();
  }, [isEnabled]);
  useOnRouteChange(scrollToTopWhenEnabled);
}
