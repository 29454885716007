import { FC } from 'react';

import { context } from './context';
import { useActiveResourceFromPath } from './use-active-resource-from-path';

export const ResourceHubStateProvider: FC = ({ children }) => {
  const activeTab = useActiveResourceFromPath();

  return <context.Provider value={{ activeTab }}>{children}</context.Provider>;
};
