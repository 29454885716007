import { FC } from 'react';

import { useColor } from '@/style';

import { InputBorder } from './components';

const InputBorderComponent: FC = (props) => {
  const negativeColor = useColor('brand-negative') as string;
  return (
    <InputBorder
      variants={{
        error: {
          borderColor: negativeColor,
        },
      }}
      {...props}
    />
  );
};

export default InputBorderComponent;
