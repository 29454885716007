import { createContext } from 'react';

export interface LayoutIds {
  image: string;
  title: string;
}

export const context = createContext<{
  layoutIds: LayoutIds | null;
  setLayoutIds: (ids: LayoutIds | null) => void;
}>(undefined as any);
