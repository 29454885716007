import { FC } from 'react';

import { StyledComponent } from '@/style';

import { getAsComponent } from './get-as-component';
import { ClickableProps } from './types/clickable-props';

export function withClickable<TProps>(
  Comp: StyledComponent<
    TProps & {
      as: FC<any>;
    }
  >
) {
  const CompWithClickable: FC<TProps & ClickableProps> = ({ as, ...rest }) => (
    <Comp {...(rest as any)} as={getAsComponent(as)} />
  );
  return CompWithClickable;
}
