import { IIterateeContext } from 'deepdash';

export class ContentIdentifier {
  constructor(
    private readonly _value: any,
    private readonly _x: IIterateeContext
  ) {}

  isComponent = (type: string) => this._value?.component === type;
  hasParentComponent = (type: string) =>
    this._x.parents?.some((p) => p.value.component === type);
}
