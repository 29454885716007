import { FC } from 'react';

import { ScrollToTopOnRouteChangeProvider } from './config-context';
import useScrollToTopOnRouteChange from './use-scroll-to-top-on-route-change';

const Hooks: FC<{
  id?: string;
}> = ({ id, children }) => {
  useScrollToTopOnRouteChange(id);
  return <>{children}</>;
};

export const ScrollToTopOnRouteChange: FC<{
  id?: string;
}> = ({ id, children }) => (
  <ScrollToTopOnRouteChangeProvider>
    <Hooks id={id}>{children}</Hooks>
  </ScrollToTopOnRouteChangeProvider>
);
