import { useEffect, useState } from 'react';

import amplitude from 'amplitude-js';

import config from '@/config';
import { dataLayer } from '@/lib/data-layer';
import { TTrackFn } from '@/lib/track/types';

import * as log from './log';

const track: TTrackFn = (eventName: string, eventId?: string, data = {}) => {
  if (config.analytics.amplitude)
    amplitude.getInstance().logEvent(eventName, {
      ...data,
      id: eventId,
    });
  if (dataLayer !== null) {
    if (eventName !== 'Route Change') {
      dataLayer.push({ event: eventName, eventId, ...data });
    }
  }
};

const useAnalyticsInit = (): {
  track: TTrackFn;
  isInited: boolean;
} => {
  const [isInited, setIsInited] = useState(false);
  useEffect(() => {
    if (config.analytics.amplitude)
      amplitude.getInstance().init(config.analytics.amplitude, undefined, {
        includeUtm: true,
      });

    setIsInited(true);
  }, []);
  return {
    track: isInited
      ? track
      : (...args: Array<any>) => {
          log.warn(
            `Cannot track as analytics has not been initialised ${JSON.stringify(
              args
            )}`
          );
        },
    isInited,
  };
};

export default useAnalyticsInit;
