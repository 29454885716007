import { motion } from 'framer-motion';

import { styled } from '@/style';

// eslint-disable-next-line import/prefer-default-export
export const InputBorder = styled(motion.div)`
  display: flex;
  border: 1px solid rgba(65, 79, 101, 0.3);
  border-radius: md;
  font-size: lg;
  line-height: lg;
  font-weight: 500;
  background-color: white;
  transition: base;
  position: relative;
  color: brand-text;
  :hover {
    border-color: palette-primary;
  }

  :after {
    pointer-events: none;
    transition: base;
    content: '';
    border: 2px solid transparent;
    position: absolute;
    z-index: infront;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-radius: md;
  }
  :focus-within,
  .focus-within {
    :after {
      border-color: palette-primary;
      border-width: 2px;
    }
  }
`;
