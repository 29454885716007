import { FC } from 'react';

import { useResolvePathWithRedirects } from './use-resolve-path-with-redirects';

export function withAutoRedirectHref<
  TProps extends { href: string | undefined }
>(Component: FC<TProps>): FC<TProps> {
  return (props) => {
    const { href } = props;
    const { resolvePathWithRedirects } = useResolvePathWithRedirects();
    const updatedHref = href ? resolvePathWithRedirects(href) : href;
    return <Component {...props} href={updatedHref} />;
  };
}
