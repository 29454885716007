import { FC, useEffect } from 'react';

import omit from 'lodash/omit';
import { useRouter } from 'next/router';

import useTrack from './context';

const useTrackRouteChange = () => {
  const { track } = useTrack();
  const router = useRouter();

  useEffect(() => {
    if (track) {
      track('Route Change', window.location.pathname, {
        path: router.asPath,
        title: document.title,
        hash: window.location.hash,
        queryString: window.location.search,
        query: omit(router.query, ['path']),
      });
    }
  }, [router.asPath, router.query, track]);
};

const TrackRoute: FC = ({ children }) => {
  useTrackRouteChange();

  return <>{children}</>;
};

export default TrackRoute;
