import { FC } from 'react';

import type { TPaletteFunction } from '@/lib/Palette';
import { ThemeProvider, useTheme } from '@/style';

import { mapPaletteToThemeVariables } from './utils';

const Provider: FC<{
  createPalette?: TPaletteFunction;
}> = ({ children, createPalette }) => {
  const theme = useTheme();

  let newTheme = theme;
  if (createPalette) {
    const newPalette = createPalette(theme.colors);

    newTheme = {
      ...theme,
      colors: {
        ...theme.colors,
        ...mapPaletteToThemeVariables(newPalette),
      },
    };
  }

  return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>;
};

export default Provider;
