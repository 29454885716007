import { FC } from 'react';

import type { TPaletteFunction } from '@/lib/Palette';

import Provider from './Provider';

const createProvider: <T extends Record<string, TPaletteFunction>>(
  palleteThemeCreators: T
) => FC<{
  palette?: keyof T;
}> =
  (palleteThemeCreators) =>
  ({ children, palette: paletteName }) => {
    const createPalette = paletteName
      ? palleteThemeCreators[paletteName]
      : undefined;

    return <Provider createPalette={createPalette}>{children}</Provider>;
  };

export default createProvider;
