import { Text } from '@welcome-ui/text';

import { styled, css, breakpoints } from '@/style';

const variants = {
  h1: css`
    font-weight: 700;
    font-size: 2xl;
    ${breakpoints({
      largeTablet: css`
        font-size: 3xl;
      `,
      desktop: css`
        font-size: 4xl;
      `,
    })}
  `,
  h2: css`
    font-weight: 700;
    font-size: xl;
    ${breakpoints({
      largeTablet: css`
        font-size: 2xl;
      `,
      desktop: css`
        font-size: 3xl;
      `,
    })}
  `,
  h3: css`
    font-weight: 600;
    font-size: lg;
    ${breakpoints({
      largeTablet: css`
        font-size: xl;
      `,
      desktop: css`
        font-size: 2xl;
      `,
    })}
  `,
  h4: css`
    font-weight: 600;
    font-size: lg;
    ${breakpoints({
      largeTablet: css`
        font-size: lg;
      `,
      desktop: css`
        font-size: xl;
      `,
    })}
  `,
  h5: css`
    font-weight: 600;
    font-size: lg;
    ${breakpoints({
      largeTablet: css`
        font-size: lg;
      `,
      desktop: css`
        font-size: lg;
      `,
    })}
  `,
  subtitle1: css`
    font-weight: 600;
    font-size: md;
    text-transform: uppercase;
    color: palette-primary;
    ${breakpoints({
      largeTablet: css`
        font-size: md;
      `,
      desktop: css`
        font-size: md;
      `,
    })}
  `,
  bodyBig: css`
    font-weight: 500;
    font-size: lg;
    line-height: lg;
  `,
  body1: css`
    font-size: md;
  `,
  body2: css`
    font-weight: 600;
  `,
  meta1: css`
    font-weight: 600;
  `,
  button: css`
    font-weight: 800;
    text-transform: uppercase;
  `,
};

type TTextVariant = keyof typeof variants;
const StyledText = styled(Text)<{
  variant?: TTextVariant;
}>`
  ${({ color = 'palette-text' }) =>
    css`
      color: ${color};
    `}

  ${({ pb, paddingBottom }) => {
    let padding = '0.7rem';
    if (pb !== undefined) padding = pb;
    else if (paddingBottom !== undefined) padding = paddingBottom;
    return css`
      padding-bottom: ${padding};
    `;
  }}
  ${({ variant }: { variant: TTextVariant }) => variant && variants[variant]}
`;

const strongVariants = {
  color: css`
    font-weight: inherit;
    color: palette-primary;
  `,
  weight: css`
    font-weight: 700;
  `,
};
export const Strong = styled.strongBox<{
  $variant?: keyof typeof strongVariants;
}>`
  ${({ $variant = 'color' }) => strongVariants[$variant]}])
`;

export const Em = styled.emBox``;
export const U = styled.uBox``;
export const Strike = styled.spanBox`
  text-decoration: line-through;
`;

export default StyledText;
