import { style } from '@xstyled/styled-components';

export interface FixedFullProps {
  fixedFull?: boolean;
}

export const fixedFull = style({
  prop: 'fixedFull',
  css: () => ({
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
});
