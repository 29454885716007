import { useMemo } from 'react';

import { nanoid } from 'nanoid';

export const useCreateArticleLayoutIds = () =>
  useMemo(
    () => ({
      image: nanoid(),
      title: nanoid(),
    }),
    []
  );
