import type { SbImage } from 'storyblok';

import resizeImage from './imageService';

export const resolveImageAttrs = (
  image: SbImage,
  width?: number
): {
  alt?: string;
  title?: string;
  id?: string;
  src: string;
} => ({
  ...(image.alt ? { alt: image.alt } : {}),
  ...(image.title ? { title: image.title } : {}),
  ...(image.id ? { id: image.id } : {}),
  src:
    width !== undefined ? resizeImage(image.filename, width) : image.filename,
});
