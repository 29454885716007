export default {
  title: {
    fontSize: '30px',
    lineHeight: '45px',
    fontWeight: 'bold',
  },
  button: {
    fontSize: 'lg',
    lineHeight: 'lg',
    color: 'palette-text',
    fontWeight: '600',
  },
};
