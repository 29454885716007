import { forwardRef } from 'react';

import NextLink from 'next/link';

import { withPathBeforeQueryParams } from '@/common/hocs/with-path-before-query-params';
import { withAutoRedirectHref } from '@/lib/redirect/with-auto-redirect-href';
import { withTrackClick } from '@/lib/track';
import { InferProps, x } from '@/style';

type LinkProps = MakePropsRequired<InferProps<typeof x.a>, 'href'>;

const LinkComponent = forwardRef<HTMLLinkElement, LinkProps>(
  ({ href, ...rest }: any, ref) => (
    <NextLink href={href} scroll={false}>
      <x.a ref={ref as any} href={href} {...rest} />
    </NextLink>
  )
);

export const UnstyledLink = withTrackClick(
  withAutoRedirectHref(withPathBeforeQueryParams(LinkComponent))
);
