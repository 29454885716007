import { useEffect } from 'react';

import { useRouter } from 'next/router';
import parseUrl from 'url-parse';

export default function useOnRouteChange(
  onRouteChange: (route: string) => void
) {
  const router = useRouter();

  useEffect(() => {
    let previousRoute: any = null;
    const handleRouteChange = (route: string) => {
      const newRoute = parseUrl(route);
      const hasPathChanged =
        previousRoute === null || newRoute.pathname !== previousRoute?.pathname;
      if (!newRoute.hash && hasPathChanged) {
        onRouteChange(route);
      }
      previousRoute = newRoute;
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRouteChange]);
}
