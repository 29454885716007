import { VFC } from 'react';

export const Mouseflow: VFC<{
  isActive?: boolean;
}> = ({ isActive }) =>
  isActive ? (
    <script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
        window._mfq = window._mfq || [];
        (function() {
          var mf = document.createElement("script");
          mf.type = "text/javascript"; mf.defer = true;
          mf.src = "//cdn.mouseflow.com/projects/c1f14aa1-10fc-4014-82e1-9b83b1ddd6f0.js";
          document.getElementsByTagName("head")[0].appendChild(mf);
        })();
      `,
      }}
    />
  ) : null;
