import { Alert } from '@welcome-ui/alert';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { styled } from '@/style';

const AlertContainer = styled.divBox`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: md;
  padding: md;
  cursor: pointer;
  z-index: overlay;
  width: 100%;
  max-width: 400px;
`;

export const PreviewAlertComponent = ({
  preview,
  redirectPath,
}: {
  preview: boolean;
  redirectPath: string;
}) => (
  <>
    {preview ? (
      // eslint-disable-next-line @next/next/link-passhref
      <Link href={`/api/preview/exit?redirect=${redirectPath}`}>
        <AlertContainer>
          <Alert p='md'>
            <Alert.Title icon={null}>You are in preview mode</Alert.Title>
            <span>Click here to exit.</span>
            {/* <Alert.Button variant='secondary'>Button</Alert.Button> */}
          </Alert>
        </AlertContainer>
      </Link>
    ) : null}
  </>
);

export default function PreviewAlert({ preview }: { preview: boolean }) {
  const route = useRouter();
  return (
    <PreviewAlertComponent preview={preview} redirectPath={route.asPath} />
  );
}
