import { useCallback, useState } from 'react';

export const useSubscribeToNewsletter = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const subscribe = useCallback(
    async (email: string) => {
      if (!isSubmitting) {
        setIsSubmitting(true);
        try {
          await fetch(
            `/api/subscribe-to-newsletter?email=${encodeURIComponent(email)}`
          );
        } finally {
          setIsSubmitting(false);
        }
      }
    },
    [isSubmitting]
  );

  return {
    isSubmitting,
    subscribe,
  };
};
