import type { Link } from 'storyblok';

import { AnchorHTMLAttributes, useCallback } from 'react';

import { useGetPathFromPageId } from './path-from-page-id';
import { useResolvePathFromSlug } from './path-from-slug-resolver';

export const useResolveAttrsFromStoryblokLink = (
  link: Link
): AnchorHTMLAttributes<{}> => {
  const { id, linktype, url, story } = link;
  const { getPathFromPageId } = useGetPathFromPageId();
  const { resolvePathFromSlug } = useResolvePathFromSlug();
  const resolvePathFromSlugOrError = useCallback((slug: string) => {
    const href = resolvePathFromSlug(slug);
    if (!href) {
      throw new Error(`Could not resolve the slug ${slug}`);
    }
    return href;
  }, []);
  switch (linktype) {
    case 'email':
      return {
        href: `mailto:${url}`,
      };
    case 'story': {
      if (story?.url) {
        return {
          href: resolvePathFromSlugOrError(story.url),
        };
      }
      const path = getPathFromPageId(id);
      if (path === undefined) {
        throw new Error(
          `Couldn't determine what path we should supply for ${JSON.stringify(
            link
          )}`
        );
      }
      return {
        href: resolvePathFromSlugOrError(url),
      };
    }
    default: {
      return {
        href: url,
      };
    }
  }
};
