import { FC, ComponentType, forwardRef } from 'react';

import useTrack from './context';

type TTrackProps = {
  trackName?: string;
  trackData?: any;
};
export default function withTrackClick<TProps>(
  Button: ComponentType<TProps>
): FC<TProps & TTrackProps> {
  const AnyButton = Button as any;
  return forwardRef<
    typeof Button,
    TProps & TTrackProps & { onClick?: (...args: any) => void }
  >(({ onClick, trackName, trackData = {}, ...props }, ref) => {
    const { track } = useTrack();
    return (
      <AnyButton
        ref={ref}
        onClick={(...events: any) => {
          if (onClick) onClick(...(events as any));
          if (trackName && track)
            track('Click', trackName, {
              pathName: window.location.pathname,
              ...trackData,
            });
        }}
        {...props}
      />
    );
  }) as any;
}
