import { styled } from '@/style';

// eslint-disable-next-line import/prefer-default-export
export const Input = styled.inputBox`
  border: none;
  outline: none;
  background: transparent;
  padding: md;
  width: 100%;
  :placeholder {
    color: #414f6580;
  }
`;
