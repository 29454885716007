import { withTrackClick } from '@/lib/track';
import { styled, css, x } from '@/style';

// import { FlattenSimpleInterpolation, StyledComponent } from 'styled-components';

type TVersion = 'primary' | 'secondary';

const ButtonWithTrack = withTrackClick(x.button);

export const variants = {
  blank: css`
    padding: 0;
    text-align: left;
  `,
  simpleLink: css`
    color: palette-primary;
    text-decoration: underline;
    font-weight: 600;
    :hover {
      text-decoration: none;
    }
  `,
  hoverColor: css`
    color: currentColor;
    transition: base;
    :hover {
      color: palette-primary;
    }
  `,
  rounded: css<{
    version?: TVersion;
  }>`
    display: inline-block;
    border-radius: default;
    transition: slow;
    font-weight: 700;
    padding: md lg;
    font-family: mont;
    transition: default;
    font-size: lg;

    box-shadow: 0px 2px 0px var(--box-shadow-color);
    transform: translateY(0);
    :hover {
      box-shadow: 0px 3px 0px var(--box-shadow-color);
      transform: translateY(-1px);
    }
    :active,
    :focus {
      box-shadow: 0px 0 0px var(--box-shadow-color);
      transform: translateY(2px);
      transition: fast;
    }
    ${({ version }) => {
      switch (version) {
        case 'secondary':
          return css`
            --box-shadow-color: ${({ theme }) =>
              theme.colors['palette-secondary-border']};
            background-color: palette-secondary;
            color: palette-secondary-text;
            border: 1px solid var(--box-shadow-color);
            font-weight: 600;
            :active,
            :focus {
              color: palette-secondary-text;
            }
          `;
        case 'primary':
        default:
          return css`
            --box-shadow-color: ${({ theme }) =>
              theme.colors['palette-primary-border']};
            border: none;
            background-color: palette-primary;
            color: palette-primary-text;
            :active,
            :focus {
              color: palette-primary-text;
            }
          `;
      }
    }}
  `,
  nav: css`
    display: inline-block;
    background-color: transparent;
    padding: sm 0;
    border-bottom: 1px solid;
    border-bottom-color: transparent;
    transition: base;
    font-family: mont;
    font-weight: 600;
    font-size: md;
    :hover {
      color: palette-primary;
    }
  `,
};

const Button = styled(ButtonWithTrack)<{
  variant?: keyof typeof variants;
  version?: TVersion;
}>`
  background: transparent;
  color: inherit;
  text-align: center;
  ${({ variant }) => (variant ? variants[variant] : variants.rounded)}
`;

export default Button;
