/**
 * For setting specific px values see:
 * /support/docs/issues/rpx-transform.md
 */

import { ROOT_FONT_RATIO } from '../constants';

export default {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 32,
  xl: 64,
  '2xl': 128,
  xxl: 128,
  header: 100,
  button: 60 * ROOT_FONT_RATIO,
};
