import { styled } from '@/style';

// eslint-disable-next-line import/prefer-default-export
const Label = styled.labelBox`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-weight: 600;
  > * + * {
    margin-top: xs;
  }
`;

export default Label;
