import { RegisterOptions } from 'react-hook-form';

import { Provider as FormItemProvider } from './FormItemContext';
import { TFormItem } from './types.d';

function withFormItem<TFormItemAdditionalProps>(
  FormItem: TFormItem<TFormItemAdditionalProps>
) {
  const AnyFormItem = FormItem as any;
  const WrappedFormItem: TFormItem<
    {
      name: string;
      options?: RegisterOptions;
    } & TFormItemAdditionalProps
  > = ({ name, options, ...rest }) => (
    <FormItemProvider name={name} options={options}>
      <AnyFormItem {...rest} />
    </FormItemProvider>
  );

  return WrappedFormItem;
}
export default withFormItem;
