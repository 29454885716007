import { style } from '@xstyled/styled-components';

export interface FlexCenterProps {
  flexCenter?: boolean;
}

export const flexCenter = style({
  prop: 'flexCenter',
  css: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
});
