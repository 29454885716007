import { useEffect } from 'react';

import { useConfig } from './config-context';

export const useDisableScrollToTopOnRouteChange = () => {
  const { setIsEnabled } = useConfig();

  useEffect(() => {
    setIsEnabled(false);
    return () => {
      setIsEnabled(true);
    };
  }, []);
};
