import { FC } from 'react';

import Head from 'next/head';

const SocialMeta: FC<{
  baseUrl?: string;
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  ogTitle?: string;
  ogUrl?: string;
  ogImage?: string;
  ogDescription?: string;
  twitterSite?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
  twitterImageAlt?: string;
}> = ({
  baseUrl,
  title,
  description,
  keywords,
  image,
  ogTitle = title,
  ogUrl = baseUrl,
  ogImage = image,
  ogDescription = description,
  twitterSite = baseUrl,
  twitterTitle = title,
  twitterDescription = description,
  twitterImage = image,
  twitterImageAlt = undefined,
}) => (
  <Head>
    {/* <!-- Social --> */}
    {title ? <meta key='title' content={description} name='title' /> : null}
    {description ? (
      <meta key='description' content={description} name='description' />
    ) : null}
    {keywords ? (
      <meta key='keywords' content={keywords} name='keywords' />
    ) : null}
    {/* <!-- Schema.org markup for Google+ --> */}
    {title ? <meta key='google-title' content={title} itemProp='name' /> : null}
    {description ? (
      <meta
        key='google-description'
        content={description}
        itemProp='description'
      />
    ) : null}
    {image ? (
      <meta key='google-image' content={image} itemProp='image' />
    ) : null}
    {/* <!-- Twitter Card data --> */}
    {twitterSite ||
    twitterTitle ||
    twitterDescription ||
    twitterImage ||
    twitterImageAlt ? (
      <meta key='twitter-card-alt' content='summary' name='twitter:card' />
    ) : null}
    {twitterSite ? (
      <meta key='twitter-url' content={twitterSite} name='twitter:site' />
    ) : null}
    {twitterTitle ? (
      <meta key='twitter-title' content={twitterTitle} name='twitter:title' />
    ) : null}
    {twitterDescription ? (
      <meta
        key='twitter-description'
        content={twitterDescription}
        name='twitter:description'
      />
    ) : null}
    {twitterImage ? (
      <meta key='twitter-image' content={twitterImage} name='twitter:image' />
    ) : null}
    {twitterImageAlt ? (
      <meta
        key='twitter-image-alt'
        content={twitterImageAlt}
        name='twitter:image:alt'
      />
    ) : null}
    {/* <!-- Open Graph data --> */}
    {ogTitle || ogUrl || ogImage || ogDescription ? (
      <meta key='og-type' content='website' property='og:type' />
    ) : null}
    {ogTitle ? (
      <meta key='og-title' content={ogTitle} property='og:title' />
    ) : null}
    {ogUrl ? <meta key='og-url' content={ogUrl} property='og:url' /> : null}
    {ogImage ? (
      <meta key='og-image' content={ogImage} property='og:image' />
    ) : null}
    {ogDescription ? (
      <meta
        key='og-description'
        content={ogDescription}
        property='og:description'
      />
    ) : null}
  </Head>
);

export default SocialMeta;
