import type { IPalette } from '@/lib/Palette';

// eslint-disable-next-line import/prefer-default-export
export const mapPaletteToThemeVariables = (palette: IPalette) => ({
  'palette-bg': palette.bg,
  'palette-text': palette.text,
  'palette-accent': palette.accent,
  'palette-subtle': palette.subtle,
  'palette-border': palette.border,
  'palette-primary': palette.primary,
  'palette-primary-text': palette.primaryText,
  'palette-primary-border': palette.primaryBorder,
  'palette-secondary': palette.secondary,
  'palette-secondary-text': palette.secondaryText,
  'palette-secondary-border': palette.secondaryBorder,
});
