import { FC, useCallback } from 'react';

import { trimStart } from 'lodash';

import { context } from './context';

export const PathFromSlugProvider: FC<{
  resolvePathFromSlug: (slug: string) => string | null;
}> = ({ resolvePathFromSlug, children }) => {
  const resolvePathFromCleanSlug = useCallback(
    (slug) => {
      const cleanSlug = trimStart(slug, '/');
      return resolvePathFromSlug(cleanSlug);
    },
    [resolvePathFromSlug]
  );
  return (
    <context.Provider
      value={{
        resolvePathFromSlug: resolvePathFromCleanSlug,
      }}
    >
      {children}
    </context.Provider>
  );
};
