import type { SbRichText } from 'storyblok';

import { FC, Fragment } from 'react';

import { Richtext } from 'storyblok-js-client';
import { render } from 'storyblok-rich-text-react-renderer';

import type { TBloks, TResolver, TConfig } from '@/lib/storyblok';
import { styled } from '@/style';

import createRenderParams from './createRenderParams';

const DefaultContainer = styled.div`
  & > * + * {
    margin-top: 0.6rem !important;
  }
`;

const RichText: FC<{
  bloks: TBloks;
  children: SbRichText;
  config: TConfig;
  resolver: TResolver;
  Container?: FC;
  StringComponent?: FC;
}> = ({
  children,
  bloks,
  config,
  Container = DefaultContainer,
  StringComponent = Fragment,
  resolver,
}) => (
  <Container>
    {typeof children === 'string' ? (
      <StringComponent>{children}</StringComponent>
    ) : (
      render(children as Richtext, createRenderParams(resolver, bloks, config))
    )}
  </Container>
);

export default RichText;
