import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

export const shape = <T extends ObjectShape>(
  fields: T
): yup.ObjectSchema<object & T, object> => {
  const obj = yup.object().required().shape(fields);
  return obj;
};

export const from = <T>(value: T) =>
  ({
    string: (...args) => {
      const obj = yup
        .string(...args)
        .default(() => (value ? obj.cast(value) : undefined)) as any;
      return obj;
    },
    number: (...args) => {
      const obj = yup
        .number(...args)
        .default(() => (value ? obj.cast(value) : undefined)) as any;
      return obj;
    },
    boolean: (...args) => {
      const obj = yup
        .boolean(...args)
        .default(() => (value ? obj.cast(value) : undefined)) as any;
      return obj;
    },
  } as Pick<typeof yup, 'string' | 'number' | 'boolean'>);

export const load = <T extends ObjectShape>(
  schema: yup.ObjectSchema<object & T, object>
) => schema.validateSync({});
