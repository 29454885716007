// eslint-disable-next-line import/first
import { shape, from, load } from '@/lib/configLoader';

const config = load(
  shape({
    env: from(process.env.NEXT_PUBLIC_ENV)
      .string()
      .required()
      .oneOf(['production', 'preview', 'development', 'test']),
    tileColor: from(process.env.NEXT_PUBLIC_TILECOLOR).string().required(),
    seo: shape({
      baseUrl: from(process.env.NEXT_PUBLIC_BASEURL || process.env.VERCEL_URL)
        .string()
        .required(),
      title: from(process.env.NEXT_PUBLIC_TITLE).string().required(),
      description: from(process.env.NEXT_PUBLIC_DESCRIPTION)
        .string()
        .required(),
      keywords: from(process.env.NEXT_PUBLIC_KEYWORDS).string().required(),
      imageUrl: from(process.env.NEXT_PUBLIC_IMAGEURL).string().required(),
      companyName: from(process.env.NEXT_PUBLIC_COMPANYNAME)
        .string()
        .required(),
      twitterUser: from(process.env.NEXT_PUBLIC_TWITTERUSER)
        .string()
        .required(),
    }),
    socialProfile: shape({
      twitter: from(process.env.NEXT_PUBLIC_SOCIALPROFILE_TWITTER)
        .string()
        .required(),
      linkedIn: from(process.env.NEXT_PUBLIC_SOCIALPROFILE_LINKEDIN)
        .string()
        .required(),
      facebook: from(process.env.NEXT_PUBLIC_SOCIALPROFILE_FACEBOOK)
        .string()
        .required(),
    }),
    analytics: shape({
      amplitude: from(process.env.NEXT_PUBLIC_AMPLITUDE).string(),
    }),
    storyblok: shape({
      token: from(process.env.NEXT_PUBLIC_STORYBLOKTOKEN).string().required(),
    }),
    mouseflow: shape({
      isActive: from(process.env.NEXT_PUBLIC_MOUSEFLOW_ISACTIVE).boolean(),
    }),
    vwo: shape({
      isActive: from(process.env.NEXT_PUBLIC_VWO_ISACTIVE).boolean(),
    }),
    cookieYes: from(process.env.NEXT_PUBLIC_COOKIEYES).string(),
    hubspotId: from(process.env.NEXT_PUBLIC_HUBSPOT_ID).string(),
    form: shape({
      contact: from(process.env.NEXT_PUBLIC_FORM_CONTACT).string().required(),
      register: from(process.env.NEXT_PUBLIC_FORM_REGISTER).string().required(),
    }),
    imageCdn: from(process.env.NEXT_PUBLIC_IMAGE_CDN).string().required(),
    housePath: from(process.env.NEXT_PUBLIC_HOUSE_PATH).string().required(),
  })
);
export default config;
