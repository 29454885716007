// eslint-disable-next-line import/no-cycle
import { utils } from '@/lib/Palette';

export const brand = {
  'brand-light-green': '#F1F5F3',
  'brand-green': '#3A6E6E',
  'brand-light-yellow': '#FFF9ED',
  'brand-yellow': '#F0CD7C',
  'brand-light-orange': '#FFF6F3',
  'brand-orange': '#EB6E46',
  'brand-dark-orange': '#CB5029',
  'brand-light-red': '#E8E2E0',
  'brand-dark': '#414F65',
  'brand-blue': '#414F65',
  'brand-beige': '#E8E2E0',
  'brand-beige-light': '#F4F1EF',
  'brand-beige-dark': '#D0C9C7',
  'brand-white': '#FAF8F7',
  'brand-bright-white': '#FDFBFA',
  'brand-grey': '#D0C9C7',
  'brand-text': '#374668',
  'brand-negative': 'rgba(246, 85, 85, 1)',
  'brand-positive': '#6CB135',
};

export default {
  text: brand['brand-text'],
  background: brand['brand-white'],
  primary: brand['brand-orange'],
  overlay: '#414f65c2',
  'overlay-text': brand['brand-white'],
  ...brand,
  ...utils.mapPaletteToThemeVariables({
    bg: brand['brand-white'],
    text: brand['brand-text'],
    accent: brand['brand-orange'],
    subtle: brand['brand-beige-light'],
    border: brand['brand-beige'],
    primary: brand['brand-orange'],
    primaryText: brand['brand-white'],
    primaryBorder: '#CB5029',
    secondary: brand['brand-white'],
    secondaryText: brand['brand-text'],
    secondaryBorder: brand['brand-beige'],
  }),
};
