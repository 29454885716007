import { InputHTMLAttributes } from 'react';

import { TFormItem, withFormItem } from '@/lib/Form';
import useFormItem from '@/lib/Form/FormItemContext';

import { Input as StyledInput } from './components';

import * as shared from '../shared';

const Input: TFormItem<InputHTMLAttributes<any>> = ({ label, ...rest }) => {
  const { registration } = useFormItem();

  return (
    <shared.Label>
      <shared.LabelText>{label}</shared.LabelText>
      <shared.InputBorder>
        <StyledInput {...rest} {...registration} />
      </shared.InputBorder>
      <shared.ErrorMessage />
    </shared.Label>
  );
};

export default withFormItem(Input);
