import { FC } from 'react';

const Hubspot: FC<{
  id?: string;
}> = ({ id }) =>
  id ? (
    <script
      type='text/javascript'
      id='hs-script-loader'
      async
      defer
      src={`//js-eu1.hs-scripts.com/${id}.js`}
    />
  ) : null;

export default Hubspot;
