import { FC, useState } from 'react';

import { context, LayoutIds } from './context';

export const ArticleLayoutIdsProvider: FC = ({ children }) => {
  const [layoutIds, setLayoutIds] = useState<LayoutIds | null>(null);
  return (
    <context.Provider
      value={{
        layoutIds,
        setLayoutIds,
      }}
    >
      {children}
    </context.Provider>
  );
};
