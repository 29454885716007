import trimEnd from 'lodash/trimEnd';

const trimPx = (text: string | number) => {
  if (typeof text === 'string') {
    return trimEnd(text, 'px');
  }
  return text;
};

export default function resizeImage(
  image: string,
  width?: number | string,
  height = 0
) {
  if (!image) return image;
  if (!width) return image;
  if (image.endsWith('.svg')) return image;
  if (image.indexOf('https://a.storyblok.com') !== 0) return image;
  const optionText = `${trimPx(width)}x${height}`;
  const imageService = 'https://img2.storyblok.com/';
  const path = image.replace('https://a.storyblok.com', '');
  const newPath = imageService + optionText + path;
  return newPath;
}
