import { FC } from 'react';

import { useCreatePathWithQueryParams } from './use-create-path-with-query-params';

export const withPathBeforeQueryParams = <
  T extends {
    href: string;
  }
>(
  Component: FC<T>
) => {
  const ComponentWithFullPath: FC<T> = (props) => {
    const { href } = props;
    const fullHref = useCreatePathWithQueryParams(href);
    return <Component {...props} href={fullHref} />;
  };

  return ComponentWithFullPath;
};
