const scale = {
  xxs: '0.75rem',
  xs: '0.8125rem',
  sm: '0.875rem',
  md: '0.9rem',
  base: '0.9rem',
  default: '0.9rem',
  lg: '1.125rem',
  xl: '1.5rem',
  xxl: '2rem',
  '2xl': '2rem',
  xxxl: '2.75rem',
  '3xl': '2.75rem',
  xxxxl: '3.5rem',
  '4xl': '3.5rem',
};

export default {
  ...scale,
  h1: scale['4xl'],
  h2: scale['2xl'],
  h3: scale.xl,
  h4: scale.lg,
  subtitle1: scale.lg,
  body1: scale.md,
  body2: scale.sm,
  meta1: scale.xs,
  button: '0.875rem',
};
