export default function CookiePolicy({ id = undefined }: { id?: string }) {
  return id ? (
    <script
      async
      defer
      id='cookieyes'
      src={`https://cdn-cookieyes.com/client_data/${id}/script.js`}
      type='text/javascript'
    />
  ) : null;
}
