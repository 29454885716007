import { StyledProps, x } from '@/style';

import { withClickable } from './with-clickable';

export const Clickable = withClickable<StyledProps>(
  ({ as, children, ...rest }) => (
    <x.div as={as} text='button' blankButton flexCenter {...rest}>
      {children}
    </x.div>
  )
);
