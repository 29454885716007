import { FC } from 'react';

import { CONTENT_TOP } from './constants';
import { context } from './context';

export const ResourceHubLayoutProvider: FC = ({ children }) => (
  <context.Provider
    value={{
      contentTop: CONTENT_TOP,
    }}
  >
    {children}
  </context.Provider>
);
